<template>
  <div class="credit-card-input">
    <div class="title--text hide--in-mobile">Isi Informasi Kartu</div>
    <!-- Preview Area -->
    <!--    <div class="card-preview" :class="cardTypeClass">-->
    <!--      <div class="card-type">-->
    <!--        <img v-if="cardTypeImage" :src="cardTypeImage" alt="Card Type" />-->
    <!--      </div>-->
    <!--      <div class="card-number">{{ formattedCardNumber }}</div>-->
    <!--      <div class="card-holder">Pemegang Kartu: {{ cardHolder || 'NAMA LENGKAP' }}</div>-->
    <!--      <div class="card-expiry">Berlaku Hingga: {{ cardExpiry || 'MM/YY' }}</div>-->
    <!--    </div>-->

    <!-- Form Fields -->
    <form @submit.prevent="handleSubmit">
      <div class="form-group">
        <label for="cardNumber">Nomor Kartu</label>
        <div style="position: relative">
          <input
            type="text"
            id="cardNumber"
            v-model="cardNumber"
            @input="onCardNumberInput"
            placeholder="XXXX XXXX XXXX XXXX"
            maxlength="19"
          />
          <img class="card--type-image" v-if="cardTypeImage" :src="cardTypeImage" alt="Card Type" />
        </div>
        <span v-if="validation.hasError('cardNumber')" class="error">
          {{ validation.firstError('cardNumber') }}
        </span>
      </div>

      <div class="form-group">
        <label for="cardHolder">Pemegang Kartu</label>
        <input type="text" id="cardHolder" v-model="cardHolder" placeholder="Nama Lengkap" />
        <span v-if="validation.hasError('cardHolder')" class="error">
          {{ validation.firstError('cardHolder') }}
        </span>
      </div>

      <div class="form-group">
        <label for="cardExpiry">Berlaku Hingga (MM/YY)</label>
        <!-- We bind the value manually and use onExpiryInput to enforce the mask -->
        <input
          type="text"
          id="cardExpiry"
          :value="cardExpiry"
          @input="onExpiryInput"
          placeholder="MM/YY"
          maxlength="5"
        />
        <span v-if="validation.hasError('cardExpiry')" class="error">
          {{ validation.firstError('cardExpiry') }}
        </span>
      </div>

      <div class="form-group">
        <label for="cardCvv">CVV</label>
        <input type="text" id="cardCvv" v-model="cardCvv" placeholder="CVV" maxlength="4" />
        <span v-if="validation.hasError('cardCvv')" class="error">
          {{ validation.firstError('cardCvv') }}
        </span>
      </div>

      <div class="d-flex justify-center">
        <button
          class="btn btn-primary"
          type="submit"
          :disabled="hasError"
          style="padding-left: 40px; padding-right: 40px; margin-top: 12px"
        >
          Lanjutkan
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { Validator } from 'simple-vue-validator';
import { mapState } from 'vuex';

export default {
  name: 'CreditCardInput',
  data() {
    return {};
  },
  // Local validators using simple-vue-validator’s Validator.
  // (This is similar to your usual pattern.)
  validators: {
    cardNumber(value) {
      const number = value.replace(/\s+/g, '');
      // Begin with a required check.
      return Validator.value(number)
        .required('Nomor kartu harus diisi.')
        .custom(async () => {
          if (!this.luhnCheck(number)) {
            return 'Nomor kartu tidak valid.';
          }
          // Check length based on detected card type.
          if (this.cardType === 'amex' && number.length !== 15) {
            return 'Nomor kartu American Express harus terdiri atas 15 angka.';
          } else if (
            ['visa', 'mastercard', 'jcb'].includes(this.cardType) &&
            number.length !== 16
          ) {
            return 'Nomor kartu harus terdiri atas 16 angka.';
          }
          // If no errors, return true.
          return;
        });
    },
    cardHolder(value) {
      return Validator.value(value).required('Nama pemegang kartu harus diisi.');
    },
    cardExpiry(value) {
      return Validator.value(value)
        .required('Berlaku hingga harus diisi.')
        .custom(async () => {
          // Expect MM/YY exactly.
          if (!/^(0[1-9]|1[0-2])\/\d{2}$/.test(value)) {
            return 'Berlaku hingga harus dalam format MM/YY.';
          }
          const [monthStr, yearStr] = value.split('/');
          const month = parseInt(monthStr, 10);
          const year = 2000 + parseInt(yearStr, 10);
          const expDate = new Date(year, month);
          const now = new Date();
          return expDate > now ? null : 'Kartu sudah tidak berlaku.';
        });
    },
    cardCvv(value) {
      if (this.cardType === 'amex') {
        return Validator.value(value)
          .required('CVV harus diisi.')
          .custom(() =>
            value.length === 4 ? null : 'CVV harus terdiri atas 4 angka untuk American Express.',
          );
      } else {
        return Validator.value(value)
          .required('CVV harus diisi.')
          .custom(() => (value.length === 3 ? null : 'CVV harus terdiri atas 3 angka.'));
      }
    },
  },
  computed: {
    ...mapState({}),
    hasError() {
      return (
        this.validation.hasError('cardNumber') ||
        this.validation.hasError('cardHolder') ||
        this.validation.hasError('cardExpiry') ||
        this.validation.hasError('cardCvv')
      );
    },
    loading: {
      get() {
        return this.$store.state.cardPayment.loading;
      },
      set(value) {
        this.$store.commit('cardPayment/SET_LOADING', value);
      },
    },
    cardNumber: {
      get() {
        return this.$store.state.cardPayment.cardNumber;
      },
      set(value) {
        this.$store.commit('cardPayment/SET_CARD_NUMBER', value);
      },
    },
    cardHolder: {
      get() {
        return this.$store.state.cardPayment.cardHolder;
      },
      set(value) {
        this.$store.commit('cardPayment/SET_CARD_HOLDER', value);
      },
    },
    cardExpiry: {
      get() {
        return this.$store.state.cardPayment.cardExpiry;
      },
      set(value) {
        this.$store.commit('cardPayment/SET_CARD_EXPIRY', value);
      },
    },
    cardCvv: {
      get() {
        return this.$store.state.cardPayment.cardCvv;
      },
      set(value) {
        this.$store.commit('cardPayment/SET_CARD_CVV', value);
      },
    },
    cardToken: {
      get() {
        return this.$store.state.cardPayment.cardToken;
      },
      set(value) {
        this.$store.commit('cardPayment/SET_CARD_TOKEN', value);
      },
    },
    // Formats the card number in groups of 4 digits.
    formattedCardNumber() {
      const digits = this.cardNumber.replace(/\s+/g, '');
      return digits.replace(/(.{4})/g, '$1 ').trim();
    },
    // Detects card type using regex. Only Visa, MasterCard, Amex, and JCB are allowed.
    cardType() {
      const number = this.cardNumber.replace(/\s+/g, '');
      if (/^4\d*/.test(number)) {
        return 'visa';
      } else if (/^(5[1-5]|2[2-7])\d*/.test(number)) {
        return 'mastercard';
      } else if (/^3[47]\d*/.test(number)) {
        return 'amex';
      } else if (/^35(?:2[89]|[3-8]\d)\d*/.test(number)) {
        return 'jcb';
      } else {
        return '';
      }
    },
    cardTypeClass() {
      return this.cardType ? `card-${this.cardType}` : '';
    },
    cardTypeImage() {
      const images = {
        visa: 'https://upload.wikimedia.org/wikipedia/commons/4/41/Visa_Logo.png',
        mastercard:
          'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a4/Mastercard_2019_logo.svg/1657px-Mastercard_2019_logo.svg.png',
        amex: 'https://upload.wikimedia.org/wikipedia/commons/3/30/American_Express_logo_%282018%29.svg',
        jcb: 'https://upload.wikimedia.org/wikipedia/en/thumb/3/3a/JCB_logo.svg/1200px-JCB_logo.svg.png',
      };
      return images[this.cardType] || '';
    },
  },
  methods: {
    // Luhn algorithm for card number validation.
    luhnCheck(val) {
      let sum = 0;
      let shouldDouble = false;
      for (let i = val.length - 1; i >= 0; i--) {
        let digit = parseInt(val.charAt(i), 10);
        if (shouldDouble) {
          digit *= 2;
          if (digit > 9) digit -= 9;
        }
        sum += digit;
        shouldDouble = !shouldDouble;
      }
      return sum % 10 === 0;
    },
    onCardNumberInput() {
      // Remove non-digits and format in groups of 4.
      const digits = this.cardNumber.replace(/[^\d]/g, '');
      this.cardNumber = digits.replace(/(.{4})/g, '$1 ').trim();
    },
    onExpiryInput(e) {
      let input = e.target.value;
      // Remove non-digits.
      input = input.replace(/[^\d]/g, '');
      // Insert a slash after two digits.
      if (input.length > 2) {
        input = input.slice(0, 2) + '/' + input.slice(2, 4);
      }
      this.cardExpiry = input;
    },
    async handleSubmit() {
      // console.log('asdasdasda', window.MidtransNew3ds)
      this.loading = true;
      // Trigger validation for all fields.
      const isValid = await this.$validate();
      if (isValid) {
        window.MidtransNew3ds.getCardToken(
          {
            card_number: this.cardNumber,
            card_exp_month: this.cardExpiry.split('/')[0],
            card_exp_year: this.cardExpiry.split('/')[1],
            card_cvv: this.cardCvv,
          },
          {
            onSuccess: async (response) => {
              console.log('Token ID 1:', response.token_id);

              this.$emit('successGetToken', response.token_id);

              // Implement success handling here
            },
            onFailure: (response) => {
              console.error('Error:', response);
              this.$swal('Oops!', 'Maaf terjadi kesalahan, silahkan coba lagi nanti', 'error');
              this.loading = false;

              // Implement error handling here
            },
          },
        );

        // alert('Form is valid! (Proceed with submission.)');
      } else {
        console.log('Credit card form is invalid!');
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/utils/variables';
.credit-card-input {
  max-width: 400px;
  margin: 20px auto;
  font-family: Arial, sans-serif;
  @media #{$phones} {
    padding: 0 12px;
  }
}

.title--text {
  text-align: center;
  margin-bottom: 12px;
  font-family: Poppins-Bold, sans-serif;
  font-size: $font-xl;
}

.card-preview {
  background: #333;
  color: #fff;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  position: relative;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card-preview .card-type img {
  height: 30px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.card-preview .card-number {
  font-size: 1.2em;
  letter-spacing: 2px;
  margin-bottom: 10px;
}

.card-preview .card-holder,
.card-preview .card-expiry {
  font-size: 0.9em;
}

/* Form Styles */
.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.form-group input:focus {
  border-color: #66afe9;
  outline: none;
}

.card--type-image {
  position: absolute;
  top: 11px;
  right: 12px;
  height: 20px;
}

.error {
  color: red;
  font-size: 0.8em;
  margin-top: 5px;
  display: block;
}

/* Optional card type background styling */
.card-visa .card-preview {
  background: linear-gradient(135deg, #1a1f71, #fff);
}

.card-mastercard .card-preview {
  background: linear-gradient(135deg, #eb001b, #f79e1b);
}

.card-amex .card-preview {
  background: linear-gradient(135deg, #2e77bb, #fff);
}

.card-jcb .card-preview {
  background: linear-gradient(135deg, #003087, #fff);
}
</style>
